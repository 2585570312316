import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';
import ProjectStore from '@/components/store/ProjectStore.vue';
import CheckoutPage from '@/components/checkout/CheckoutPage.vue';
import OrderReceiptPage from '@/components/receipts/OrderReceiptPage.vue';

export default createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'LandingPage',
			component: LandingPage
		},
		{
			path: '/landing/:id',
			name: 'OrgLandingPage',
			component: LandingPage
		},
		{
			name: 'all-sales',
			path: '/projects/:id',
			component: ProjectStore
		},
		{
			name: 'ad-sales',
			path: '/ads/:id',
			component: ProjectStore
		},
		{
			name: 'book-sales',
			path: '/books/:id',
			component: ProjectStore
		},
		{
			path: '/checkout',
			component: CheckoutPage
		},
		{
			path: '/receipts/:id',
			component: OrderReceiptPage
		}
	]
});