<template>
	<v-app data-app :style="{ '--background-image': `url('${backgroundUrl}')` }">
		<v-navigation-drawer
			v-model="drawer"
			app
			temporary
		/>
		<v-app-bar app color="headerPrimary" class="main-toolbar">
			<img class="app-logo" :src="appLogo">
			<v-toolbar-title class="text-white">
				{{ $store.state.drawerTitle }}
			</v-toolbar-title>
		</v-app-bar>
		<v-main>
			<v-container fluid fill-height class="transition-wrapper app-content">
				<router-view v-slot="{ Component }">
					<transition name="fade">
						<component :is="Component" />
					</transition>
				</router-view>
			</v-container>
		</v-main>

		<v-footer
			class="app-footer"
			height="auto"
			v-if="$store.state.footerBranding"
		>
			<div class="app-footer-text">
				Brought to you by {{ $store.state.footerBranding }} (<a target="_blank" :href="privacyPolicy">Privacy Policy</a>)
			</div>
		</v-footer>

		<update-notification />
	</v-app>
</template>

<script>
import UpdateNotification from '@/components/utils/UpdateNotification.vue';
import logo from '@/assets/logo.svg';
import background from '@/assets/background.png';

export default {
	name: 'App',
	data: function() {
		return {
			drawer: false
		};
	},
	computed: {
		appLogo: function() {
			if(this.$store.state.drawerLogo) {
				return this.$store.state.drawerLogo;
			} else {
				return logo;
			}
		},
		backgroundUrl() {
			return this.$store.state.customBackground ?? background;
		},
		privacyPolicy() {
			return import.meta.env.VITE_PLIC_BOOKS_HOST + '/PrivacyPolicy.html';
		}
	},
	methods: {
		toggleDrawer: function() {
			this.drawer = !this.drawer;
		}
	},
	components: {
		UpdateNotification
	}
};
</script>

<style>
	html {
		overflow: auto !important;
	}
	html, body, .text-h1, .text-h2, .text-h3, .text-h4, .text-h5 {
		font-family: "Inter", serif !important;
	}

	body {
		margin: 0;
		text-align: center;
	}

	html, body, .v-application, #app {
		height: 100%;
		height: 100vh;
		height: -webkit-fill-available;
	}

	.v-application > .v-application__wrap {
		min-height: 100vh !important;
		min-height: -webkit-fill-available !important;
	}

	.v-menu__content {
		text-align: left;
	}

	.v-application__wrap:before {
		content: "";
		position: fixed;
		left: 0;
		right: 0;

		display: block;
		width: 100%;
		height: 100%;

		background-image: var(--background-image);
		background-repeat: no-repeat;
		background-size: cover;
	}

	.app-content {
		height: 100%;
	}
	@media (max-width: 600px) {
		.app-content:before {
			background-image: url("./assets/background.png?size=600");
		}
	}
	@media (max-width: 960px) {
		.app-content:before {
			background-image: url("./assets/background.png?size=960");
		}
	}
	@media (max-width: 1400px) {
		.app-content:before {
			background-image: url("./assets/background.png?size=1400");
		}
	}

	.v-application .main-toolbar .app-logo {
		max-height: 100%;
		margin-left: 1.25em;
		padding: 4px 0;
	}

	.v-application .main-toolbar .v-toolbar__content {
		text-align: start;
	}
	@media (max-width: 600px) {
		.v-application .main-toolbar .v-toolbar__content {
			justify-content: center;
			justify-items: center;
		}
		.v-application .main-toolbar .v-toolbar-title {
			display: none;
		}

		.v-application .main-toolbar .app-logo {
			max-height: 80%;
		}
	}

	.v-application .v-main {
		z-index: 1;
	}
	.v-application .app-footer {
		background: transparent !important;
		z-index: 1;
	}
	.app-footer-text {
		color: var(--color-zinc-500);
		width: 100%;
	}
	.app-footer-text > a {
		color: var(--color-zinc-500) !important;
	}

	.transition-wrapper > .layout:nth-child(2) {
		display: none;
	}
	.fade-enter-active, .fade-leave-active {
		transition-property: opacity;
		transition-duration: 0.3s;
	}

	.fade-enter-active {
		transition-delay: 0.3s;
	}

	.fade-enter, .fade-leave-active {
		opacity: 0
	}

	.v-list-item__content {
		text-align: left;
	}

	.v-application a {
		color: var(--color-future-blue-600);
	}
</style>