import { createStore } from 'vuex';
import cart from './cart';
import project from './project';
import createPersistedState from 'vuex-persistedstate';

const state = {
	drawerTitle: 'Captura Yearbooks Store',
	drawerLogo: null,
	customBackground: null,
	footerBranding: null
};
const getters = {

};
const mutations = {
	setDrawer: function(state, payload) {
		state.drawerTitle = payload.title;
		state.drawerLogo = payload.logo;
		state.customBackground = payload.customBackground;
		state.footerBranding = payload.branding;
	},
	resetDrawer: function(state) {
		state.drawerTitle = 'Captura Yearbooks Store';
		state.drawerLogo = null;
		state.customBackground = null;
		state.footerBranding = null;
	}
};

export default function myCreateStore() {
	return createStore({
		state,
		getters,
		mutations,
		modules: {
			cart,
			project
		},
		plugins: [createPersistedState()]
	});
}