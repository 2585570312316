<template>
	<v-row justify="center">
		<v-col sm="12" md="6" v-if="valid">
			<product-listing />
		</v-col>

		<v-col sm="12" md="4" v-if="valid">
			<cart-summary />
		</v-col>

		<v-col sm="12" v-if="loading">
			<v-progress-circular indeterminate />
		</v-col>

		<v-col sm="12" md="8" lg="4" v-if="errorMessage">
			<v-alert type="error" :model-value="!!errorMessage">
				{{ errorMessage }}
			</v-alert>
		</v-col>
	</v-row>
</template>

<script>
import ProductListing from '@/components/store/ProductListing.vue';
import CartSummary from '@/components/store/CartSummary.vue';

export default {
	name: 'ProjectStore',
	data: function() {
		return {
			loading: true,
			errorMessage: null
		};
	},
	computed: {
		valid: function() {
			return !this.loading && !this.errorMessage;
		}
	},
	mounted: function() {
		this.$store.dispatch({
			type: 'loadProject',
			id: this.$route.params.id,
			page: this.$route.name
		}).then((project) => {
			this.loading = false;
			this.$store.commit({
				type: 'setDrawer',
				title: project.name,
				logo: project.brandingLogo,
				customBackground: project.customBackground,
				branding: project.brandingName
			});

			if(this.$store.state.cart.coupons.length > 0) {
				this.$store.dispatch({
					type: 'applyCoupon',
					projectId: this.$route.params.id,
					couponCode: this.$store.state.cart.coupons[0].code
				}).then((coupon) => {
					if(!coupon) {
						this.$store.commit('removeCoupons');
					}
				});
			}
		}).catch((error) => {
			this.loading = false;
			this.errorMessage = error.message;

			if(error.responseData) {
				this.$store.commit({
					type: 'setDrawer',
					title: error.responseData.store.title,
					logo: error.responseData.brandingLogo,
					customBackground: error.responseData.customBackground,
					branding: error.responseData.brandingName
				});
			} else {
				this.$store.commit({
					type: 'resetDrawer'
				});
			}
		});
	},
	components: {
		ProductListing,
		CartSummary
	}
};
</script>

<style>

</style>