import axios from 'axios';

export default {
	getProject({projectId, page}) {
		return new Promise((resolve, reject) => {
			axios.get(import.meta.env.VITE_PLIC_BOOKS_HOST + '/api/v1/yearbook-store?projectId=' + projectId).then((response) => {
				const onlyYearbooks = page === 'book-sales';
				const onlyAds = page === 'ad-sales';

				if((onlyYearbooks && response.data.store.enabled) || (onlyAds && response.data.store.ads) || (!onlyAds && !onlyYearbooks && (response.data.store.enabled || response.data.store.ads))) {
					response.data.store.price = parseFloat((parseFloat(response.data.store.price) / 100).toFixed(2));
					response.data.store.shippingFee = parseFloat((parseFloat(response.data.store.shippingFee || 0) / 100).toFixed(2));
					response.data.store.addOns.forEach(function(addOn) {
						addOn.price = parseFloat((parseFloat(addOn.price) / 100).toFixed(2));
					});
					(response.data.store.tieredDiscounts || []).forEach(function(tieredDiscount) {
						tieredDiscount.discount = parseFloat((parseFloat(tieredDiscount.discount) / 100).toFixed(2));
					});

					let ads = response.data.store.ads;
					if(ads) {
						for(var id in ads) {
							ads[id] = parseFloat((parseFloat(ads[id]) / 100).toFixed(2));
						}
					}
					if(response.data.store.handlingFeeFlat) {
						response.data.store.handlingFeeFlat = parseFloat((parseFloat(response.data.store.handlingFeeFlat) / 100).toFixed(2));
					}
					window.storeCurrency = response.data.currency;
					resolve(response.data);
				} else {
					let errorMessage = 'Store disabled';
					if(response.data.store.reasonDisabled && (onlyYearbooks || !onlyAds)) {
						errorMessage += ': ' + response.data.store.reasonDisabled;
					} else if(response.data.store.adsReasonDisabled && (onlyAds || !onlyYearbooks)) {
						errorMessage += ': ' + response.data.store.adsReasonDisabled;
					}

					let error = new Error(errorMessage);
					error.responseData = response.data;
					reject(error);
				}
			}).catch((error) => {
				if(error.response && error.response.data) {
					reject(new Error(error.response.data.reason));
				} else {
					reject(new Error('Failed to load store'));
				}
			});
		});
	}
};