<template>
	<div class="landing-page text-white">
		<img class="app-logo" :src="logo">
		
		<div class="subheading" v-if="loading">
			<v-progress-circular indeterminate />
		</div>
		<div class="subheading" v-else>
			<v-card class="access-code-form">
				<v-card-title class="title">
					<h1>Yearbook Store</h1>
				</v-card-title>

				Please enter the access code you received from your school or contact them for your access code

				<v-card-text>
					<v-text-field
						variant="outlined"
						fluid
						v-model="accessCode"
						label="Access Code"
						class="access-code"
						name="accessCode"
						@keyup="accessCodeError = false"
						@keyup.enter="checkAccessCode" 
						:error-messages="accessCodeErrors"
					/>
					<v-btn color="primary" @click="checkAccessCode" :loading="accessCodeLoading">
						Go To Book
					</v-btn>
					<v-alert type="error" class="error" v-if="accessCodeError">
						Access code not found.  Please contact your school for the correct access code.
					</v-alert>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import logo from '@/assets/light_logo.svg';

export default {
	name: 'LandingPage',
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	validations() {
		// Org landing pages allow searching by short code but main page requires only valid access codes
		if(this.$route.params.id) {
			return {
				accessCode: {
					required,
					minLength: minLength(3)
				}
			};
		} else {
			return {
				accessCode: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(6)
				}
			};
		}
	},
	data: () => ({
		loading: false,
		logo,
		accessCode: '',
		accessCodeLoading: false,
		accessCodeError: false
	}),
	computed: {
		accessCodeErrors() {
			const errors = [];
			if(this.v$.accessCode.$dirty) {
				if(this.$route.params.id) {
					!this.v$.accessCode.required.$invalid || errors.push('Access Code is required');
					!this.v$.accessCode.minLength.$invalid || errors.push('Access Code has too few character - must be at least 3 characters');
				} else {
					!this.v$.accessCode.required.$invalid || errors.push('Access Code is required');
					!this.v$.accessCode.minLength.$invalid || errors.push('Access Code has too few character - must be exactly 6 characters');
					!this.v$.accessCode.maxLength.$invalid || errors.push('Access Code has too many character - must be exactly 6 characters');
				}
			}

			return errors;
		}
	},
	methods: {
		checkAccessCode() {
			if(this.accessCodeLoading) {
				return;
			}

			this.v$.$touch();
			this.accessCodeError = false;
			if(!this.v$.$error) {
				this.accessCodeLoading = true;

				let url = `${import.meta.env.VITE_PLIC_BOOKS_HOST}/api/v1/yearbook-store?accessCode=${this.accessCode}`;
				if(this.$route.params.id) {
					url += `&orgId=${this.$route.params.id}`;
				}

				axios.get(url).then(response => {
					let urlString = response.data.url;
					this.accessCodeLoading = false;
					if(!urlString.includes(window.location.host)) {
						this.accessCodeError = true;
					} else {
						let url = new URL(urlString);
						this.$router.push(url.pathname);
					}
				}).catch(error => {
					console.warn(error);
					this.accessCodeLoading = false;
					this.accessCodeError = true;
				});
			}
		}
	},
	mounted: function() {
		if(this.$route.params.id) {
			this.loading = true;
			let url = `${import.meta.env.VITE_PLIC_BOOKS_HOST}/api/v1/yearbook-store?orgId=${this.$route.params.id}`;
			axios.get(url).then(response => {
				const { brandingLogo, brandingName, customBackground } = response.data;

				this.$store.commit('setDrawer', {
					title: 'Captura Yearbooks Store',
					logo: brandingLogo,
					branding: brandingName,
					customBackground
				});
				if(brandingLogo) {
					this.logo = brandingLogo;
				}

				this.loading = false;
			}).catch(() => {
				this.loading = false;
			});
		} else {
			this.$store.commit('setDrawer', {
				title: 'Captura Yearbooks Store',
				logo: null,
				branding: null
			});
		}
	}
};
</script>

<style scoped>
.landing-page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

@media all and (orientation:portrait) {
	.app-logo {
		width: 90%;
	}
}

@media all and (orientation:landscape) {
	.app-logo {
		width: 20%;
	}

	.landing-page {
		align-self: flex-start;
	}
}

.access-code-form {
	max-width: 40em;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1em;
}
.access-code-form .title {
	margin-top: 1em;
}
.access-code {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
}
.error {
	margin-top: 1em;
}
</style>