<template>
	<div>
		<v-progress-circular indeterminate v-if="loading" />
		<stripe-form
			v-if="type == 'stripe'"
			@next-step="nextStep"
			@prev-step="prevStep"
		/>

		<v-alert
			type="error"
			:model-value="!!errorMessage"
			transition="scale-transition"
		>
			{{ errorMessage }}
		</v-alert>

		<v-btn
			color="primary"
			@click="goToStore"
			v-if="canGoBackToStore"
			class="back-button"
		>
			Back
		</v-btn>
	</div>
</template>

<script>
import StripeForm from '@/components/checkout/StripeForm.vue';
const priceChangedMessage = 'The price has changed.  Press back to get the updated price.';
const couponInvalidMessage = 'Coupon is no longer valid.  Press back to get the updated price.';
const addOnInvalidMessage = 'Selected add-on is no longer valid.  Press back to get the updated price.';
const priceToLowMessage = 'Order total must be at least $1';

export default {
	name: 'PaymentInformation',
	emits: ['prev-step', 'next-step'],
	props: {
		isVisible: {
			default: false
		}
	},
	data: function() {
		return {
			loading: true,
			errorMessage: null
		};
	},
	computed: {
		type: function() {
			if(this.loading || this.errorMessage) {
				return 'disabled';
			}

			return this.$store.state.cart.merchantType;
		},
		canGoBackToStore: function() {
			return this.errorMessage && (
				[priceChangedMessage, couponInvalidMessage, addOnInvalidMessage, priceToLowMessage].includes(this.errorMessage)
				|| this.errorMessage.includes('not enabled')
				|| this.errorMessage.includes('requires customer notes')
				|| this.errorMessage.includes('Last day to purchase add-ons was')
			);
		}
	},
	watch: {
		isVisible: function(newValue) {
			if(!newValue) {
				return;
			}

			this.load();
		}
	},
	methods: {
		nextStep: function(getOrderDataCallback) {
			this.$emit('next-step', getOrderDataCallback);
		},
		prevStep: function() {
			this.$emit('prev-step');
		},
		goToStore: function() {
			this.$router.push('/projects/' + this.$store.state.project.id);
			document.documentElement.scrollTop = 0;
		},

		load() {
			if(this.$store.getters.total < 1.00) {
				this.errorMessage = priceToLowMessage;
				this.loading = false;
				return;
			}

			this.loading = true;
			this.$store.dispatch({
				type: 'startOrder',
				id: this.$store.state.project.id
			}).then(() => {
				this.loading = false;

				if(!window.Stripe) {
					this.errorMessage = 'Stripe failed to load so we cannot process your order.  Please try refreshing your browser.';
				}
			}).catch((error) => {
				let errorMessage = 'Failed to load order';

				// This can happen when there are timeouts during the charge process
				if(error && error.response && error.response.data && error.response.data.reason) {
					if(error.response.data.reason === 'Not allowed: Already paid') {
						let accessCode = this.$store.state.cart.accessCode;
						if(accessCode) {
							this.$store.commit('clearCart');
							this.$router.push('/receipts/' + accessCode);
						}
					} else if(error.response.data.reason.indexOf('does not match') !== -1) {
						errorMessage = priceChangedMessage;
					} else if(error.response.data.reason.indexOf('$0 orders are not supported') !== -1) {
						errorMessage = '$0 orders are not supported';
					} else if(error.response.data.reason.indexOf('Invalid coupon') !== -1) {
						errorMessage = couponInvalidMessage;
						this.$store.commit('removeCoupons');
					} else if(error.response.data.reason.indexOf('Invalid add-on') !== -1) {
						errorMessage = addOnInvalidMessage;
					} else if(error.response.data.reason.includes('not enabled') || error.response.data.reason.includes('requires customer notes') || error.response.data.reason.includes('Last day to purchase add-ons was')) {
						errorMessage = error.response.data.reason;
					}
				}
				console.error(error);

				this.loading = false;
				this.errorMessage = errorMessage;
			});
		}
	},
	mounted() {
		if(this.isVisible) {
			this.load();
		}
	},
	
	components: {
		StripeForm
	}
};
</script>

<style scoped>
.back-button {
	margin-top: 1em;
}
</style>