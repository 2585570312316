import cartAPI from '@/api/cart';
import clone from 'clone';
import itemPrice from '@/utils/itemPrice';
import { Decimal } from 'decimal.js';

const state = getDefaultState();

function getDefaultState() {
	return {
		orderId: null,
		items: [],
		merchantType: null,
		merchantKey: null,
		accessCode: null,
		email: null,
		shipping: null,
		shippingInformation: null,
		coupons: [],
		isDirectShipping: true
	};
}

export const getters = {
	itemsCount: function(state) {
		return state.items.length;
	},
	subTotal: function(state) {
		return state.items.reduce((subTotal, item) => {
			return (item.quantity * itemPrice(item)) + subTotal;
		}, 0);
	},
	preTaxTotal(state, getters) {
		return Math.max(0, getters.subTotal + (getters.shippingFee || 0) - getters.discounts);
	},
	taxableTotal(state, getters, rootState) {
		let total = getters.subTotal;

		if(rootState.project.shippingSalesTax !== false) {
			total += getters.shippingFee || 0;
		}

		return Math.max(0, total - getters.discounts);
	},
	tax: function(state, getters, rootState) {
		let taxPercentage = new Decimal(rootState.project.taxPercentage || 0);
		let taxCents = taxPercentage.mul(getters.taxableTotal);
		return taxCents.round().div(100).toNumber();
	},
	handlingFee(state, getters, rootState) {
		if(rootState.project.handlingFeeFlat) {
			return rootState.project.handlingFeeFlat;
		} else {
			let subTotal = new Decimal(getters.subTotal).sub(getters.discounts);
			let fee = rootState.project.handlingFeePercentage || 0;

			// We want to return the rounded amount otherwise 0.3 cents of tax + 0.3 cents of handlingFee adds a penny when they are two separate charges that are calculated independently of each other
			return subTotal.mul(fee).round().div(100).toNumber();
		}
	},
	total: function(state, getters) {
		let total = new Decimal(getters.preTaxTotal).add(getters.tax).add(getters.handlingFee);
		return total.toDecimalPlaces(2).toNumber();
	},
	discounts(state, getters) {
		return getters.couponDiscounts + getters.tieredDiscount;
	},
	couponDiscounts(state) {
		return (state.coupons || []).reduce((total, coupon) => {
			return total + coupon.price;
		}, 0);
	},
	tieredDiscount(state, getters, rootState) {
		let closestTieredDiscount = null;
		let tieredDiscounts = rootState.project.tieredDiscounts || [];
		tieredDiscounts.forEach((tieredDiscount) => {
			if(getters.totalYearbooks >= tieredDiscount.quantity) {
				if((closestTieredDiscount && tieredDiscount.quantity > closestTieredDiscount.quantity) || !closestTieredDiscount) {
					closestTieredDiscount = tieredDiscount;
				}
			}
		});

		if(closestTieredDiscount) {
			return closestTieredDiscount.discount;
		} else {
			return 0;
		}
	},
	nextTieredDiscount(state, getters, rootState) {
		let currentDiscount = getters.tieredDiscount;

		let tieredDiscounts = rootState.project.tieredDiscounts || [];
		let nextTieredDiscount = tieredDiscounts.find((tieredDiscount) => {
			return tieredDiscount.quantity === (getters.totalYearbooks + 1);
		});

		if(nextTieredDiscount) {
			return {
				quantity: nextTieredDiscount.quantity,
				discount: nextTieredDiscount.discount - currentDiscount
			};
		} else {
			return null;
		}
	},
	fullName: function(state) {
		var lineItem = state.items[0];
		if(!lineItem || !lineItem.firstName || !lineItem.lastName) {
			return null;
		}

		return lineItem.firstName + ' ' + lineItem.lastName;
	},
	totalYearbooks(state) {
		return state.items.filter((item) => {
			return item.product.id === 'yearbook' || item.product.id === 'adopt-a-book';
		}).length;
	},
	hasYearbook(state) {
		return state.items.filter((item) => {
			return item.product.id === 'yearbook' || item.product.id === 'adopt-a-book';
		}).length > 0;
	},
	hasAd(state) {
		return state.items.filter((item) => {
			return item.product.id === 'ads';
		}).length > 0;
	},
	hasPersonalizedPages(state) {
		return state.items.filter((item) => {
			return (item.addOns || []).filter((addOn) => {
				return addOn.type && addOn.type.indexOf('Personalized pages') !== -1;
			}).length > 0;
		}).length > 0;
	},
	rawShippingFee(state, getters, rootState) {
		if(state.shipping && getters.hasYearbook) {
			if(rootState.project.perYearbookShippingFee) {
				return getters.totalYearbooks * state.shipping;
			} else {
				return state.shipping;
			}
		} else {
			return null;
		}
	},
	shippingFee(state, getters) {
		if(state.isDirectShipping) {
			return getters.rawShippingFee;
		} else {
			return null;
		}
	}
};
export const mutations = {
	removeLineItemFromCart: function(state, { item }) {
		let index = state.items.indexOf(item);
		if(index !== -1) {
			state.items.splice(index, 1);
		}
	},
	addProductToCart: function(state, { product }) {
		if(product.unique) {
			state.items.forEach((item, i) => {
				if(item.product.id === product.id) {
					state.items.splice(i, 1);
				}
			});
		}

		product = clone(product);
		let item = {
			product: product,
			addOns: (product.addOns || []).filter((addOn) => {
				return addOn.enabled === true;
			}),
			quantity: 1,
			firstName: '',
			lastName: '',
			teacher: '',
			grade: ''
		};
		delete item.product.addOns;
		state.items.push(item);
	},
	setOrderId: function(state, id) {
		state.orderId = id;
	},
	setAccessCode: function(state, accessCode) {
		state.accessCode = accessCode;
	},
	setCartItems: function(state, items) {
		state.items = items;
	},
	clearCart: function(state) {
		var newState = getDefaultState();
		for(var name in newState) {
			state[name] = newState[name];
		}
	},
	updateCartPrices: function(state, products) {
		state.items.forEach(function(cartItem) {
			products.forEach(function(product) {
				if(cartItem.product.id === product.id) {
					cartItem.product.price = product.price;
					if(product.types) {
						cartItem.product.price = product.types.filter((type) => {
							return cartItem.product.type === type.id;
						})[0].price;
					}
					cartItem.product.name = product.name;

					let cartAddOns = (cartItem.addOns || []);
					for(let i = 0; i < cartAddOns.length; i++) {
						let cartAddOn = cartAddOns[i];
						let productAddOn = (product.addOns || []).find(productAddOn => cartAddOn.id === productAddOn.id);
						if(productAddOn) {
							cartAddOn.price = productAddOn.price;
							cartAddOn.description = productAddOn.description;
						} else {
							cartAddOns.splice(i, 1);
							i--;
						}
					}
				}
			});
		});
	},
	setShippingFee: function(state, shipping) {
		state.shipping = shipping;
		state.isDirectShipping = true;
	},
	setMerchantAccount: function(state, merchant) {
		state.merchantType = merchant.type;
		state.merchantKey = merchant.key;
	},
	setPersonalInformation: function(state, { firstName, lastName, teacher, grade, email }) {
		state.firstName = firstName;
		state.lastName = lastName;
		state.teacher = teacher;
		state.grade = grade;
		state.email = email;
	},
	setShippingInformation: function(state, shippingInformation) {
		state.shippingInformation = shippingInformation;
	},
	setIsDirectShipping(state, isDirectShipping) {
		state.isDirectShipping = isDirectShipping;
	},
	applyCoupon(state, coupon) {
		state.coupons.length = 0;
		state.coupons.push(coupon);
	},
	removeCoupons(state) {
		state.coupons = [];
	}
};

export const actions = {
	getOrder: function({ commit }, payload) {
		return new Promise((resolve, reject) => {
			cartAPI.getOrder({
				accessCode: payload.accessCode
			}).then(({order, store, brandingName, brandingLogo, customBackground}) => {
				commit('setDrawer', {
					title: store.title,
					logo: brandingLogo,
					customBackground,
					branding: brandingName
				});

				resolve({order, store});
			}).catch((error) => {
				reject(error);
			});
		});
	},
	startOrder: function({ state, commit, getters }, payload) {
		return new Promise((resolve, reject) => {
			let coupons = (state.coupons || []).map((coupon) => {
				let copy = Object.assign({}, coupon);
				copy.price = copy.price * 100;
				return copy;
			});

			var firstItem = state.items[0];
			var data = {
				projectId: payload.id,
				firstName: firstItem.firstName,
				lastName: firstItem.lastName,
				teacher: firstItem.teacher,
				grade: firstItem.grade,
				orderTotal: getters.total,
				cart: state.items,
				coupons: coupons,
				shippingFee: (state.shipping !== null) ? getters.shippingFee : null,
				shippingInformation: state.isDirectShipping ? state.shippingInformation : null
			};

			if(state.orderId) {
				data.orderId = state.orderId;
			}

			cartAPI.postOrder(data).then(({order, merchant}) => {
				commit('setOrderId', order.orderId);
				commit('setMerchantAccount', merchant);
				commit('setAccessCode', order.accessCode);

				resolve(state);
			}).catch(function(error) {
				reject(error);
			});
		});
	},
	chargeOrder: function({ state, commit }, payload) {
		return new Promise((resolve, reject) => {
			cartAPI.chargeOrder({
				orderId: payload.orderId,
				chargeToken: payload.chargeToken,
				email: payload.email,
				accessCode: payload.accessCode
			}).then(() => {
				commit('clearCart');

				resolve(state);
			}).catch(function(error) {
				reject(error);
			});
		});
	},
	applyCoupon({ commit }, payload) {
		return new Promise((resolve, reject) => {
			cartAPI.checkCoupon({
				projectId: payload.projectId,
				couponCode: payload.couponCode
			}).then(({ coupon }) => {
				if(coupon) {
					coupon.price = parseFloat((parseFloat(coupon.price) / 100).toFixed(2));

					commit('applyCoupon', coupon);
				}

				resolve(coupon);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};